@import "../globals.scss";

.flex {
  display: flex;
  width: 100%;
  @include phone {
    flex-direction: column;
  }
  & > * {
    margin-left: 62px;
    flex: 1;
    min-width: calc(50% - 31px);
    &:first-child {
      margin-left: 0;
    }
    @include phone {
      margin-left: 0;
      max-width: unset;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  @include phone {
    margin-bottom: 20px;
  }
  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #595959;
    margin-bottom: 8px;
    span {
      color: #f50202;
      font-size: 20px;
      transform: translate(-4px, 3px);
    }
  }
  .inputContainer {
    display: flex;
    align-items: center;
    border: 2px solid #b9b7b7;
    border-radius: 6px;
    padding-left: 20px;
    min-height: 48px;
    // z-index: 1;
    &.errored {
      border-color: #ef3b5b;
    }
    input,
    textarea {
      flex: 1;
      background: #ffffff;
      padding: 12.5px 0px;
      border: none;
      font-size: 16px;
      line-height: 19px;
      width: 0;
      margin-right: 20px;
      letter-spacing: 0.4px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #b9b7b7;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
    textarea {
      resize: none;
      height: 110px;
    }
  }
}

.phone_code {
  font-size: 16px;
  line-height: 19px;
  margin-left: 15px;
  margin-right: 10px;
}
.countrySelect {
  position: relative;
  .current {
    display: flex;
    align-items: center;
    cursor: pointer;
    .image {
      width: 30px;
      display: grid;
      place-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .arrow {
      width: 15px;
      height: 15px;
      flex: 1;
      display: grid;
      place-items: center;
    }
  }
}

.countryList {
  position: absolute;
  max-height: 200px;
  overflow-y: scroll;
  background-color: white;
  left: -20px;
  width: 300px;
  bottom: 100%;
  margin-bottom: 20px;
  z-index: 1;
}
.country {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
  img {
    width: 30px;
    margin-right: 20px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;
  border-top: 1px solid #e6e5e5;
}

.businessName {
  background: #f6f6ff;
  border-radius: 6px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #5555a4;
  padding: 15px;
  margin-bottom: 30px;
  // max-width: 304px;
}

.error {
  display: flex;
  // background: #fff3f5;
  border-radius: 10px;
  // padding: 18px 14px;
  align-items: center;
  margin-top: 14px;
  z-index: 0;
  .icon {
    min-width: 20px;
  }
  p {
    margin-left: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #ef3b5b;
  }
}

.forced {
  opacity: 0.7;
  cursor: not-allowed;
  * {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.select {
  background-color: white;
}
