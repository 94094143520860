@import "../../globals.scss";

.container {
  padding: 30px 0;
  display: flex;
  margin: auto;
  max-width: 1200px;
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #2c3966;
    padding-bottom: 52px;
    @include phone {
      font-size: 20px;
      line-height: 53px;
      padding-bottom: 33px;
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  @include Gap(33px);
  align-items: stretch;
  justify-content: center;
  margin: auto;
}

.cardOuter {
  flex: 1 1 300px;
}

.cardContainer {
  // max-width: 320px;
  min-width: 300px;
  height: 100%;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 14px 25px 25px 25px;
  height: 100%;
  border: 1px solid rgba(202, 202, 202, 0.97);
  border-radius: 10px;
  h3 {
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: bold;
    font-size: 27.7607px;
    line-height: 67px;
  }
}
.span {
  width: 31.88px;
  padding: 14px 25px;
  margin: 0;
}

.section {
  padding: 13.6px 0;
  border-bottom: 1px solid #e6e5e5;
  &:last-child {
    border-bottom: none;
  }
  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #434343;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #535353;
    overflow: hidden;
    transition: 0.5s;
    max-height: 100px;
  }
}

.fees {
  display: flex;
  flex-direction: column;
  @include Gap(14px);
  border-top: 1px solid #e6e5e5;
  padding: 13.6px 0;
}

.fee {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
  }
}

.select {
  align-self: center;
  margin: 16px 0;
  width: 100%;
}

.testimony {
  margin-top: 80px;
  padding: 0 30px;
  p {
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #6f6f6f;
  }
  .person {
    display: flex;
    align-items: center;
    margin-top: 30px;
    h3 {
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      margin-left: 8px;
    }
  }
}

.short {
  .section {
    cursor: pointer;
    &.collapsed {
      p {
        max-height: 0;
      }
    }
  }
  .select {
    padding: 12px;
    font-size: 14px;
    line-height: 18px;
  }
}

.sectionHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .arrow {
    display: grid;
    place-items: center;
    transition: 0.5s;
    &.open {
      transform: rotate(180deg);
    }
  }
}
