.container {
  margin-top: 41px;
}

.innerContainer {
  display: flex;
}

.main {
  flex: 1;
  margin-right: 100px;
}

.text {
  h1 {
    font-size: 32px;
    line-height: 56px;
    color: #37436c;
  }
  p {
    font-size: 18px;
    line-height: 27px;
    color: #7a7a7a;
  }
  h2 {
    font-size: 24px;
    line-height: 56px;
    color: #37436c;
    margin-top: 41px;
  }
}

.form {
  margin-top: 31px;
  .flex {
    display: flex;
    .input {
      margin-right: 32px;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      flex: 1;
      input {
        margin-top: 8px;
        padding: 15px 20px;
        border: 1.5px solid #b9b7b7;
        border-radius: 6px;
      }
    }
  }
}

.dashLink {
  margin-top: 20px;
}
