@import "../globals.scss";

.container {
  display: flex;
}

.illustration {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 175px;
  max-width: 175px;
  margin-right: 20px;
  transform: translateY(-50px);
  @include phone {
    transform: none;
    max-width: 107px;
    min-width: 107px;
  }
  position: relative;
  .absolute {
    position: absolute;
    @include phone {
      position: unset;
    }
  }
}

.genie {
  clip-path: polygon(0 0, 0 101%, 100% 101%, 100% 0);
  & > span {
    transform: translate(8px, 8px);
    animation: genieRise 0.5s;
  }
}

.prompts {
  margin: 0 0 30px 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  @include phone {
    margin: 0 0 20px 0;
  }
}

.promptWrapper {
  display: flex;
  @include phone {
    .illustration {
      display: none;
    }
    &:first-child {
      .illustration {
        display: block;
      }
    }
  }
}

.prompt {
  background: #f6f6ff;
  border-radius: 32px;
  padding: 30px;
  margin-bottom: 13px;
  opacity: 1;
  transition: 0.5s;
  flex: 1;
}
.action {
  align-self: center;
  margin: auto;
  transition: 0.5s;
  .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #37436c;
    background-color: white;
    border-radius: 5px;
    transition: 0.5s;
    display: grid;
    place-items: center;
    &.checked {
      background-color: #37436c;
      border-color: #fff;
    }
    & > * {
      transform: translate(-0.5px, 1px);
    }
  }
}
.hidden {
  transition: 0s;
  transform: translateX(-20px);
  opacity: 0;
}

.title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #4d5883;
  margin-bottom: 5px;
  & > * {
    margin-right: 5px;
  }
}

.description {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #7171ad;
}

.servicePrompt {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #7171ad;
  margin-bottom: 16px;
}

.components {
  margin-top: 15px;
}

@keyframes genieRise {
  from {
    transform: translate(0px, 50px);
  }
}
