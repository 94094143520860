@import "../../globals.scss";

.collapsible {
  @include tablet {
    position: fixed;
    width: 100%;
    margin: 0;
    left: 0;
    right: 0;
    max-width: unset;
    border-radius: 32px 32px 0px 0px;
    transition: 0.2s;
    z-index: 15;
    height: calc(100% - 90px);
    overflow-y: auto;
    &.closed {
      top: 100%;
    }
    &.open {
      top: 90px;
    }
    background-color: white;
  }
}

.container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  padding: 0 30px;
  margin-bottom: 32px;
  min-width: 387px;
  align-self: start;
  @include tablet {
    border-color: transparent;
  }
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000036;
  z-index: 14;
  transition: 0.2s;
  opacity: 0;
  pointer-events: none;

  @include tablet {
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.section {
  padding: 22px 0;
  border-bottom: 1px solid #e6e5e5;
  display: flex;
  flex-direction: column;
  &:last-child {
    border-bottom: none;
  }
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #2c3966;
  }
  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #1a1a1a;
  }
  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #1a1a1a;
    margin-bottom: 19px;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin-bottom: 12px;
  }

  & > *:first-child {
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    margin-right: 5px;
  }

  & > *:last-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #1a1a1a;
  }
  &:last-child {
    p {
      margin-bottom: 0;
    }
  }
  button {
    display: flex;
    align-items: center;
    min-width: 81px;
    p {
      margin: 0;
      color: $secondary;
      margin-left: 5px;
    }
    &:hover p {
      color: white;
    }
  }
}

h3.price {
  color: $secondary;
}

.missing {
  margin-bottom: 20px;
}

.missingText {
  display: flex;
  align-items: flex-end;
  align-self: flex-start;
  p {
    margin: 0;
    max-width: 250px;
    line-height: 27px;
    margin-right: 10px;
  }
  .info {
    flex: 1;
    transform: translateY(2px);
    min-width: 15px;
    justify-self: flex-start;
    position: relative;
    @include tablet {
      // display: none;
    }
  }
}

.head {
  position: sticky;
  top: 0;
  background-color: white;
  padding-top: 0;
}

.close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  height: 24px;
  .circle {
    display: none;
    width: 24px;
    background-color: #e5e5e5;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: 20px;
    margin-bottom: -20px;
    z-index: 5;
    @include tablet {
      display: flex;
    }
  }
}

.tooltip {
  transition: 0.2s;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  width: 300px;
  right: -100px;
  transform: translateY(5px);
  .main {
    background: #ffffff;
    box-shadow: 0px 6.35687px 6.35687px rgba(155, 155, 155, 0.25);
    border-radius: 15.8922px;
    display: flex;
    padding: 19px;
    svg {
      fill: #4d5883;
      min-width: 14.72px;
      margin-right: 6px;
      margin-top: 5px;
    }
    h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #4d5883;
    }
    h6 {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #565656;
    }
  }
  .tri {
    height: 20px;
    width: 20px;
    background-color: white;
    transform: translate(183px, -50%) scale(1.3) rotate(-45deg);
    box-shadow: 0px 6.35687px 6.35687px rgba(155, 155, 155, 0.25);
    clip-path: polygon(-100% -100%, 0 100%, 100% 100%);
  }
}

.info {
  &:hover {
    .tooltip {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    }
  }
}

.coupon {
  .inputContainer {
    border: 2px solid #dddddd;
    margin-bottom: 18.5px;
    border-radius: 10px;
    background: #ffffff;
    display: flex;
    width: 100%;
    button {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #e92169;
      background-color: transparent;
      border: none;
      padding: 0 20px;
      cursor: pointer;
    }
  }
  input {
    background-color: transparent;
    font-size: 14px;
    line-height: 26px;
    color: #313131;
    padding: 14px 20px;
    border: none;
    flex: 1;
    &:focus {
      outline: none;
    }
  }
  &.applied {
    .inputContainer {
      background-color: #ffebf2;
      border-color: #ffebf2;
    }
    input {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.strike {
  color: #aaaaaa;
  text-decoration: line-through;
  margin-left: 10px;
}

.appliedCheck {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 20px;
  p {
    margin-left: 8px;

    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #24a548;
  }
}
.couponError {
  p {
    margin-left: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #ef3b5b;
  }
}
.addon {
  display: flex;
  align-items: center;
  span {
    margin-right: 8px;
    display: grid;
    place-items: center;
    width: 18px;
    &.clickable {
      cursor: pointer;
    }
    &.unclickable,
    &.unclickable * {
      fill: #c2c2c2;
      pointer-events: none;
    }
  }
}

.details {
  margin-top: 16px;
}
