@import "../../globals.scss";

.float {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4.98164px 24.9082px rgba(154, 154, 154, 0.25);
  border-radius: 4.98164px;
  @include phone {
    flex-direction: column;
  }
  margin-bottom: 50px;
}

.options {
  width: 200px;
  border-right: 3px solid #efefef;
  h3 {
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    padding: 10px;
    @include phone {
      flex: 1;
    }
  }
  .option {
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    padding: 14px;
    background: #ffedf3;
    border-left: 7px solid #e92169;
    @include phone {
      flex: 1;
    }
  }
  @include phone {
    display: flex;
    width: unset;
  }
}

.payment {
  flex: 1;
  padding: 30px;
  label {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #313131;
    margin: 8px 0;
    span {
      color: #f50202;
      font-size: 20px;
      transform: translate(-4px, 3px);
    }
  }
}

.inputContainer {
  margin-bottom: 16px;
  flex: 1;
}

.input {
  background: #ffffff;
  border: 2px solid #dddddd;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px 27px;
}

.flex {
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 15px;
  }
}

.brand {
  height: 13px;
  width: 50px;
  display: grid;
  place-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.check {
  display: flex;
  cursor: pointer;
  margin-bottom: 16px;
  svg {
    min-width: 20px !important;
    min-height: 20px !important;
  }
  p {
    margin-left: 16px;
    span {
      color: #e92169;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.terms {
  display: flex;
  flex-direction: column;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #2c3966;
  }
  .text {
    // width: 649px;
    height: 366px;
    background: #f4f4f4;
    margin: 25px 0;
    overflow-y: auto;
    & > * {
      margin-bottom: 10px;
    }
    a {
      text-decoration: underline;
    }
  }
  button {
    margin: auto;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include phone {
    button {
      padding: 14px 20px;
      font-size: 16px;
      line-height: 20px;
      flex: 1;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
