.container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(85, 85, 164, 0.11);
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  hr {
    border: 1px solid #e6e5e5;
    transition: 0.2s;
    &.fade {
      opacity: 0;
      border: 0px solid #e6e5e5;
    }
  }
}

.main {
  padding: 12px;
  display: flex;
  .image {
    width: 85.12px;
    height: 90px;
    background: #f1f4ff;
    border-radius: 3.08406px;
    display: grid;
    place-items: center;
    margin-right: 12px;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #5555a4;
      margin-bottom: 12px;
      max-width: 170px;
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        span:first-child {
          font-weight: bold;
          font-size: 20px;
          line-height: 21px;
          color: #2c3966;
        }
        span:last-child {
          font-size: 12px;
          line-height: 21px;
          color: #c2c2c2;
        }
      }
    }
  }
}

.toggle {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #8b8b8b;
    margin-left: 8px;
    width: 80px;
  }
  & > span {
    margin-top: 2px !important;
    transition: 0.5s;
  }
}

.features {
  max-height: 0px;
  padding: 0px 16px;
  transition: 0.2s;
  overflow: hidden;
}

.feature {
  transition: 0.2s;
  display: flex;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  p {
    margin-left: 10px;
    font-size: 12px;
    line-height: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  .bullet {
    min-width: 14px;
  }
}

.collapsed {
  .feature {
    opacity: 0;
  }
}

.expanded {
  .features {
    max-height: 500px;
    padding: 12px 16px;
  }
  .toggle > span {
    transform: rotateX(180deg);
    margin-top: -2px !important;
  }
}
