@import "../../globals.scss";

.container {
  padding-top: 50px;
  form {
    margin: 24px auto -10px auto;
    max-width: 600px;
    width: 100%;
  }
}

.modal {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 70px;
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #4d5883;
    margin-bottom: 32px;
  }
  .button {
    display: flex;
  }
  button {
    margin-left: auto;
    width: 200px;
  }
}

.container {
  padding: 70px;
  background: #fafbff;
  border-bottom: 7px solid #ecf0ff;
  @include tablet {
    padding: 50px 20px;
  }
  .innerClassName {
    padding: 0;
  }
}

.head {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #2c3966;
  margin-top: 20px;
}
.subTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: #7a7a7a;
  margin-top: 15px;
}

.trust {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 10px 32px rgba(239, 239, 239, 0.4);
  border-radius: 8px;
  padding: 20px 40px;
  & > span {
    width: 1px;
    background-color: #dcdcdc;
    align-self: stretch;
    margin: 0 40px;
  }
  & > div {
    max-width: 270px;
  }
  @include phone {
    flex-direction: column;
    & > span {
      height: 1px;
      width: unset;
      margin: 20px 0;
    }
  }
}
.shopper {
  display: flex;
  align-items: center;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1a1a1a;
    max-width: 167px;
    margin-left: 11px;
    span {
      color: #4079eb;
      font-weight: bold;
    }
  }
}

.testimony p {
  // font-family: Circular Std;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #6f6f6f;
}

.newOrder {
  max-width: 800px;
  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #4d5883;
  }
  .cards {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
  .card {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 9px;
    border: 0.928519px solid #d1d1d1;
    padding: 20px;
    &:first-child {
      margin-right: 50px;
    }
    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 25px;
        &:nth-child(2) {
          color: rgba(0, 0, 0, 0.46);
        }
      }
    }
    .business {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #e6e5e5;
      margin-bottom: 15px;
      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 7px;
        color: #4d5883;
      }
    }
    button {
      margin-top: auto;
    }
    &.old {
      min-height: 350px;
    }
    &.new {
      min-height: 250px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        background: #fff8fa;
        border-color: #e92169;
      }
      .plusIcon {
        height: 66px;
        width: 66px;
        display: grid;
        place-items: center;
        background-color: $secondary;
        border-radius: 5000px;
        & > * {
          transform: scale(2.5);
        }
      }
      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: $secondary;
        margin-top: 23px;
      }
    }
  }
}
