.container {
  width: 100%;
  height: 100%;
  display: flex;
  margin: auto;
}
.loader {
  margin: auto;
  display: flex;
  align-items: center;
  border-radius: 999px;
  color: #e61d5e;
  width: 319.21px;
  padding: 6px;
  &.dark {
    background: #2a3357;
  }
  &.light {
    background: #ffe8f1;
  }
  svg {
    animation: rotating 1s infinite;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}
.text {
  margin-left: 16px;
}
.loading {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
}
.message {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #e61d5e;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
