@import "../../globals.scss";

.container {
  background-color: white;
  left: 0;
  top: 75px;
  position: fixed;
  z-index: 1;
  width: 100%;
  box-shadow: 0px 4px 50px 0px #e1e1e13d;
  clip-path: polygon(0 1%, 100% 1%, 100% 200%, 0, 200%);
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;
}

.innerContainer {
  display: flex;
  align-items: center;
  @include Gap(16px);
  padding: 13px 0;
  min-width: 1200px;
  padding: 13px 20px;
  @include tablet {
  }
}

.crumb {
  cursor: pointer;
  user-select: none;
  display: flex;
  padding: 7px 15px;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
  // white-space: nowrap;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
  .check,
  .warn {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(120%, -50%);
    display: grid;
    place-items: center;
    transition: 0.2s;
  }
}

.past {
  padding-right: 28px;
  p {
    color: #24a548;
  }
  .check {
    transform: translate(0, -50%);
  }
}

.present {
  background: #ffe3ed;
  border-radius: 4px;
  p {
    color: #e92169;
  }
}

.future {
  p {
    color: #c2c2c2;
  }
  pointer-events: none;
}

.maxPage {
  padding-right: 28px;
  p {
    color: #f8791e;
  }
  .warn {
    transform: translate(0, -50%);
  }
}

// .clickable {
//   cursor: pointer;
// }
