.container {
  display: flex;
  flex: 1;
  position: relative;
  select {
    display: none;
  }
  .selectSelected {
    padding: 15px 20px 15px 0;
    flex: 1;
    user-select: none;
    cursor: pointer;
    &.allowCustom {
      cursor: text;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    &.placeholder {
      line-height: 0;
      color: #b9b7b7;
    }
    input {
      background: transparent !important;
    }
  }
}

.dropDown {
  padding: 3px;
  position: absolute;
  top: 100%;
  left: -20px;
  right: 0;
  z-index: 5;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 50px;
  &.upward {
    bottom: 100%;
    margin-bottom: 10px;
    top: unset;
  }
}

.selectItems {
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 2px;
  &::-webkit-scrollbar {
    width: 8px;
    margin: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dedede;
    border-radius: 10px;
  }
  span {
    margin-right: 8px !important;
  }
  .head {
    display: flex;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    background-color: white;
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #1a1a1a;
    }
  }
  .item {
    padding: 8px 15px;
    margin: 2px 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:hover {
      background: #f6f6ff;
    }
    &.selected {
      color: #3b466e;
    }
  }
}
