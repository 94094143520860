$primary: #37436c;
$secondary: #e92169;

@mixin phone-small {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 650px) {
    @content;
  }
}

@mixin phone-large {
  @media (max-width: 750px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 990px) {
    @content;
  }
}

@mixin tablet-large {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin pc-small {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin Gap($gap) {
  & > * {
    margin: #{$gap * 0.5};
  }
  margin: #{$gap * -0.5};
}
